<template>
  <div class="loader" v-show="loading"></div>
</template>

<script>
  import {mapState} from 'vuex';

  export default {
    computed: {
      ...mapState([
        'loading'
      ])
    }
  };
</script>

<style lang="scss" scoped>
  .loader {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    background-color: rgba($color: #fff, $alpha: .5);

    &:before {
      content: '';
      display: block;
      width: 1.3em;
      height: 1.3em;
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 20px;
      border-radius: 50%;
      animation: spinner .7s infinite ease;
      transform: translate(0, -50%);
    }
  }

  @keyframes spinner {
    0%,
    100% {
      box-shadow: 0em -2.6em 0em 0em #f68b29, 1.8em -1.8em 0 0em rgba(246,139,41, 0.2), 2.5em 0em 0 0em rgba(246,139,41, 0.2), 1.75em 1.75em 0 0em rgba(246,139,41, 0.2), 0em 2.5em 0 0em rgba(246,139,41, 0.2), -1.8em 1.8em 0 0em rgba(246,139,41, 0.2), -2.6em 0em 0 0em rgba(246,139,41, 0.5), -1.8em -1.8em 0 0em rgba(246,139,41, 0.7);
    }
    12.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(246,139,41, 0.7), 1.8em -1.8em 0 0em #f68b29, 2.5em 0em 0 0em rgba(246,139,41, 0.2), 1.75em 1.75em 0 0em rgba(246,139,41, 0.2), 0em 2.5em 0 0em rgba(246,139,41, 0.2), -1.8em 1.8em 0 0em rgba(246,139,41, 0.2), -2.6em 0em 0 0em rgba(246,139,41, 0.2), -1.8em -1.8em 0 0em rgba(246,139,41, 0.5);
    }
    25% {
      box-shadow: 0em -2.6em 0em 0em rgba(246,139,41, 0.5), 1.8em -1.8em 0 0em rgba(246,139,41, 0.7), 2.5em 0em 0 0em #f68b29, 1.75em 1.75em 0 0em rgba(246,139,41, 0.2), 0em 2.5em 0 0em rgba(246,139,41, 0.2), -1.8em 1.8em 0 0em rgba(246,139,41, 0.2), -2.6em 0em 0 0em rgba(246,139,41, 0.2), -1.8em -1.8em 0 0em rgba(246,139,41, 0.2);
    }
    37.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(246,139,41, 0.2), 1.8em -1.8em 0 0em rgba(246,139,41, 0.5), 2.5em 0em 0 0em rgba(246,139,41, 0.7), 1.75em 1.75em 0 0em #f68b29, 0em 2.5em 0 0em rgba(246,139,41, 0.2), -1.8em 1.8em 0 0em rgba(246,139,41, 0.2), -2.6em 0em 0 0em rgba(246,139,41, 0.2), -1.8em -1.8em 0 0em rgba(246,139,41, 0.2);
    }
    50% {
      box-shadow: 0em -2.6em 0em 0em rgba(246,139,41, 0.2), 1.8em -1.8em 0 0em rgba(246,139,41, 0.2), 2.5em 0em 0 0em rgba(246,139,41, 0.5), 1.75em 1.75em 0 0em rgba(246,139,41, 0.7), 0em 2.5em 0 0em #f68b29, -1.8em 1.8em 0 0em rgba(246,139,41, 0.2), -2.6em 0em 0 0em rgba(246,139,41, 0.2), -1.8em -1.8em 0 0em rgba(246,139,41, 0.2);
    }
    62.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(246,139,41, 0.2), 1.8em -1.8em 0 0em rgba(246,139,41, 0.2), 2.5em 0em 0 0em rgba(246,139,41, 0.2), 1.75em 1.75em 0 0em rgba(246,139,41, 0.5), 0em 2.5em 0 0em rgba(246,139,41, 0.7), -1.8em 1.8em 0 0em #f68b29, -2.6em 0em 0 0em rgba(246,139,41, 0.2), -1.8em -1.8em 0 0em rgba(246,139,41, 0.2);
    }
    75% {
      box-shadow: 0em -2.6em 0em 0em rgba(246,139,41, 0.2), 1.8em -1.8em 0 0em rgba(246,139,41, 0.2), 2.5em 0em 0 0em rgba(246,139,41, 0.2), 1.75em 1.75em 0 0em rgba(246,139,41, 0.2), 0em 2.5em 0 0em rgba(246,139,41, 0.5), -1.8em 1.8em 0 0em rgba(246,139,41, 0.7), -2.6em 0em 0 0em #f68b29, -1.8em -1.8em 0 0em rgba(246,139,41, 0.2);
    }
    87.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(246,139,41, 0.2), 1.8em -1.8em 0 0em rgba(246,139,41, 0.2), 2.5em 0em 0 0em rgba(246,139,41, 0.2), 1.75em 1.75em 0 0em rgba(246,139,41, 0.2), 0em 2.5em 0 0em rgba(246,139,41, 0.2), -1.8em 1.8em 0 0em rgba(246,139,41, 0.5), -2.6em 0em 0 0em rgba(246,139,41, 0.7), -1.8em -1.8em 0 0em #f68b29;
    }
  }
</style>